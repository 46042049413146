header {
  @apply fixed top-0 left-0 w-full bg-mono-100 border-b-2 border-mono-0;
  z-index: 2000;

  .t1-gok-headerTopBar {
    @apply bg-mono-0 py-1 text-mono-100;
    height: 40px;
    transition: all 250ms ease-in-out;

    .t1-localeControl {
      ul {
        li {
          @apply uppercase text-lg;

          &:not(&:last-child) {
            :after {
              content: "|";
            }
          }

          .t1-locale--active {
            @apply no-underline;
            font-weight: 900;
          }
        }
      }
    }

    .t1-gok-headerTopBar-right {
      @apply text-2xl;

      ul li {
        &:not(:first-child) {
          @apply ml-12;
        }
      }

      img {
        width: 15px;
        margin-right: 7px;
        display: inline-block;
        vertical-align: middle;

        &.icon-search {
          width: 16px;
        }

        &.icon-cart {
          width: 20px;
        }

        &.icon-profile {
          width: 15px;
        }
      }
    }
  }

  .t1-gok-headerWrapper {
    @apply flex justify-between items-end relative py-4;
    height: 110px;
    transition: all 250ms ease-in-out;

    .t1-gok-header-left, .t1-gok-header-right {
      width: 50%;
    }

    .t1-gok-header-left {
      @apply invisible xl:visible;
      .t1-mainNav {
        li {
          @apply text-xl 2xl:text-2xl;

          &:not(:first-child) {
            @apply lg:ml-4 2xl:ml-10;
          }
        }
      }
    }

    .t1-gok-header-right {
      @apply flex justify-end items-end;
    }

    .t1-gok-header-logo {
      @apply absolute left-1/2 top-1/2;
      transform: translate(-50%, -50%);
      height: 4rem;
      transition: all 250ms ease-in-out;

      img {
        max-height: 100%;
      }
    }
  }

  .t1-mainNav {
    @apply flex-auto pr-8;

    ul {
      @apply flex;
    }

    li {
      @apply uppercase text-lg mx-2;

      a {
        @apply transition-colors;
      }
    }
  }

  #app.t1-app--scrolled & {
    .t1-gok-headerTopBar {
      margin-top: -40px;
    }

    .t1-gok-headerWrapper {
      height: 70px;

      .t1-gok-header-logo {
        height: 2.5rem;
      }
    }
  }
}
