footer {
  .t1-go-footerTop {
    ul li {
      a {
        @apply px-6 py-7 text-2xl block leading-none;
      }
    }
  }

  .t1-go-footerBottom {
    .lvl51-copyright {
      @apply mr-8 text-center;

      img {
        width: 200px;
      }
    }

    .t1-socialMediaIcons {
      @apply px-4 lg:px-20;
      > div {
        @apply text-2xl xs:text-3xl lg:text-5xl;
      }
    }
  }
}
