@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwind-custom.css";
@import "header.css";
@import "footer.css";
@import "icomoon-iconfont.css";
@import "transitions.css";
@import "media-library-card.css";

:root {
  --scrollbar-width: 0px;
  --font-base: Source Sans Pro, sans-serif;
  --font-hightlight: Abril Fatface, cursive;
}

body {
  font-family: var(--font-base);
  font-weight: 500;
}

h1, h2 {
  font-family: var(--font-highlight);
}

.t1-editorContent {
  a {
    @apply underline transition-colors text-primary hover:text-primary-darker;
    word-break: break-all;
  }
}
