@import "./tailwind-utilities.css";

/* https://tailwindcss.com/docs/adding-base-styles */
@layer base {
  html, body {
    @apply w-full h-full;
  }

  body {
    @apply antialiased font-medium text-mono-textBase;
    font-family: var(--font-base);
  }

  h1, h2 {
    @apply uppercase;
  }

  h1 {
    @apply text-3xl sm:text-4xl xl:text-6xl;
  }

  h2 {
    @apply text-3xl sm:text-3xl xl:text-5xl;
  }

  h3 {
    @apply text-xl font-bold uppercase;
  }

  h5 {
    @apply font-bold;
  }

  img {
    @apply max-w-full h-auto border-0;
  }

  p {
    @apply m-0;
  }

  [v-cloak] {
    @apply hidden;
  }

  iframe {
    @apply max-w-full;
  }
}
