/* https://tailwindcss.com/docs/adding-new-utilities */
@layer utilities {
  .t1-container {
    @apply mx-auto;
    width: theme('screens.xl');
    max-width: 90%;
  }

  .t1-go-container {
    @apply w-full px-10;
  }

  .t1-container-lg {
    @apply mx-auto;
    width: theme('screens.lg');
    max-width: 90%;
  }

  .t1-container-md {
    @apply mx-auto;
    width: theme('screens.md');
    max-width: 90%;
  }

  .video-container {
    @apply w-full h-0 relative;
    padding-bottom: 56.25%;

    iframe {
      @apply absolute top-0 left-0 w-full h-full z-10;
    }
  }

  .btn {
    @apply px-5 py-2 font-bold;
    @apply outline-none !important;
    border-radius: 20px 20px 20px 0;

    &[disabled] {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .btn-sm {
    @apply px-2 py-1 text-sm;
  }

  .btn-primary {
    @apply transform transition-colors bg-primary text-primary-contrast hover:bg-primary-darker uppercase;
  }

  .btn-primary-outline {
    @apply transform transition-colors bg-mono-100 border-2 border-primary text-primary hover:bg-primary hover:text-primary-contrast uppercase;
  }

  .btn-secondary {
    @apply transform transition-colors bg-secondary text-secondary-contrast hover:bg-secondary-darker uppercase;
  }

  .btn-secondary-outline {
    @apply transform transition-colors bg-mono-100 border-2 border-secondary text-secondary hover:bg-secondary hover:text-secondary-contrast uppercase;
  }

  .btn-mono-0-outline {
    @apply transform transition-colors border border-mono-0 text-mono-0 hover:bg-mono-0 hover:text-mono-100;
  }

  .btn-mono-100-outline {
    @apply transform transition-colors border border-mono-100 text-mono-100 hover:bg-mono-100 hover:text-mono-0;
  }

  a.btn {
    display: inline-block;
  }

  .t1-separator {
    &:after {
      @apply relative block bg-primary bottom-0 my-7;
      content: "";
      width: 130px;
      height: 1px;
    }

    &.t1-separator--center {
      &:after {
        @apply mx-auto;
      }
    }
  }

  .t1-editorContent {
    a {
      @apply underline transition-colors text-primary hover:text-primary-darker;
      word-break: break-all;
    }

    p:not(:first-child) {
      @apply mt-2;
    }
  }
}
