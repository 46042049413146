.element.t1__subplatform__heroblock {
  @apply w-full max-w-full;

  .elementInner {
    @apply w-full max-w-full;
  }

  .t1-heroSlide {
    @apply relative overflow-hidden;

    .t1-heroSlide-image {
      @apply w-full h-0 bg-cover bg-no-repeat z-10;
    }

    .t1-heroSlide-videoContainer {
      @apply w-full h-0 z-10;
    }

    .t1-heroSlide-image, .video-container {
      padding-bottom: 133.33%; /* 3/4 */
      @media screen and (min-width: theme('screens.xs')) {
        padding-bottom: 100%; /* 1/1 */
      }
      @media screen and (min-width: theme('screens.sm')) {
        padding-bottom: 75%; /* 4/3 */
      }
      @media screen and (min-width: theme('screens.md')) {
        padding-bottom: 56.25%; /* 16/9 */
      }
      @media screen and (min-width: theme('screens.lg')) {
        padding-bottom: 43.75%; /* 16/7 */
      }
    }

    .t1-heroSlide-overlay {
      @apply p-8 z-20 absolute left-1/2 bottom-14 text-center lg:max-w-2xl;
      border-radius: 40px;
      transform: translateX(-50%);

      > div {
        @apply relative z-50 text-mono-100;

        .t1-blockComponent-title {
          @apply font-light uppercase text-3xl sm:text-4xl xl:text-7xl mb-8 lg:mb-14;
        }

        .t1-blockComponent-content {
          @apply text-base;
        }
      }

      @media screen and (min-width: calc(theme('screens.lg') - 1px)) {
        @apply w-full;
      }

      @media screen and (min-width: theme('screens.lg')) {
        @apply left-10 bottom-auto text-left;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .VueCarousel {
    .VueCarousel-navigation {
      @apply hidden;

      @media screen and (min-width: theme('screens.xl')) {
        @apply block absolute right-0 flex bottom-0;

        .VueCarousel-navigation-button {
          @apply relative flex justify-center items-center bg-mono-100 bg-opacity-50 backdrop-filter backdrop-blur-2xl w-20 h-20;
          @apply m-0 left-0 right-auto transform-none outline-none !important;

          i {
            @apply text-3xl absolute flex justify-center items-center transition-colors text-mono-100 border-mono-100 w-3/4 h-3/4 rounded-full;
            border: 1px solid;

            &:before {
              @apply relative block;
            }
          }

          &.VueCarousel-navigation--disabled {
            @apply opacity-100 bg-opacity-25;
          }

          &.VueCarousel-navigation-prev {
            i:before {
              left: -2px;
              transform: rotate(180deg);
            }
          }

          &.VueCarousel-navigation-next {
            i:before {
              right: -2px;
            }
          }
        }
      }
    }

    .VueCarousel-pagination {
      @apply absolute w-auto left-1/2 bottom-7;
      transform: translateX(-50%) translateY(0);

      .VueCarousel-dot-container {
        @apply flex;
        @apply m-0 !important;

        .VueCarousel-dot {
          @apply relative m-1 p-1 outline-none bg-transparent rounded-full !important;
          border: 1px solid theme('colors.mono.100');

          &:after {
            @apply absolute top-1/2 left-1/2 w-full h-full bg-mono-100 transition-opacity opacity-0 transform -translate-y-1/2 -translate-x-1/2 rounded-full;
            content: "";
          }

          &.VueCarousel-dot--active, &:hover {
            &:after {
              @apply opacity-100;
            }
          }
        }
      }
    }
  }
}
