.t1-gok-discoverMenu .t1-gok-discoverMenu-headline {
    font-weight: 300;
    font-size: 4.5rem;
    line-height: 1;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  .t1-gok-discoverMenu.t1-gok-discoverMenu--mobile .t1-gok-discoverMenu-headline {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      line-height: 2.5rem;
      margin-top: 0px;
      margin-bottom: 3rem;
      text-align: left;
    }
  .t1-gok-discoverMenu.t1-gok-discoverMenu--mobile .t1-gok-discoverMenu-headline a {
        display: flex;
        align-items: center;
      }
  .t1-gok-discoverMenu.t1-gok-discoverMenu--mobile .t1-gok-discoverMenu-headline svg {
        font-size: 2.25rem;
        line-height: 2.5rem;
        margin-right: 1rem;
      }
  .t1-gok-discoverMenu nav {
    margin-top: 4rem;
    width: 100%
  }
  @media (min-width: 1024px) {
    .t1-gok-discoverMenu nav {
      padding-left: 2rem;
      padding-right: 2rem;
      width: 50%;
    }
  }
  .t1-gok-discoverMenu nav > div {
      font-weight: 300;
      font-size: 2.25rem;
      line-height: 2.5rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  .t1-gok-discoverMenu nav ul li {
        --tw-border-opacity: 1;
        border-color: rgba(15, 16, 18, var(--tw-border-opacity));
        border-top-width: 2px;
        font-size: 1.5rem;
        line-height: 2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
  .t1-gok-discoverMenu nav ul li:hover a {
            box-shadow: 0 2px 0 0 #000;
          }
  @media screen and (max-width: calc(1024px - 1px)) {.t1-gok-discoverMenu {
    transform: translateX(100%);

    --tw-bg-opacity: 1;

    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

    height: 100%;

    position: absolute;

    top: 0px;

    right: 0px;

    width: 100%;

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms;

    transition-property: transform;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

    .t1-gok-mainMenu--level-2 .t1-gok-discoverMenu {
      transform: translateX(0);
    }
  }
